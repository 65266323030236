// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_TH_TH: CharacterDict = {
  Backquote: [/* _ */ 0x005f, /* % */ 0x0025],
  Digit1: [/* ๅ */ 0x0e45, /* + */ 0x002b],
  Digit2: [/* / */ 0x002f, /* ๑ */ 0x0e51],
  Digit3: [/* - */ 0x002d, /* ๒ */ 0x0e52],
  Digit4: [/* ภ */ 0x0e20, /* ๓ */ 0x0e53],
  Digit5: [/* ถ */ 0x0e16, /* ๔ */ 0x0e54],
  Digit6: [/* ุ */ 0x0e38, /* ู */ 0x0e39],
  Digit7: [/* ึ */ 0x0e36, /* ฿ */ 0x0e3f],
  Digit8: [/* ค */ 0x0e04, /* ๕ */ 0x0e55],
  Digit9: [/* ต */ 0x0e15, /* ๖ */ 0x0e56],
  Digit0: [/* จ */ 0x0e08, /* ๗ */ 0x0e57],
  Minus: [/* ข */ 0x0e02, /* ๘ */ 0x0e58],
  Equal: [/* ช */ 0x0e0a, /* ๙ */ 0x0e59],
  KeyQ: [/* ๆ */ 0x0e46, /* ๐ */ 0x0e50],
  KeyW: [/* ไ */ 0x0e44, /* " */ 0x0022],
  KeyE: [/* ำ */ 0x0e33, /* ฎ */ 0x0e0e],
  KeyR: [/* พ */ 0x0e1e, /* ฑ */ 0x0e11],
  KeyT: [/* ะ */ 0x0e30, /* ธ */ 0x0e18],
  KeyY: [/* ั */ 0x0e31, /* ํ */ 0x0e4d],
  KeyU: [/* ี */ 0x0e35, /* ๊ */ 0x0e4a],
  KeyI: [/* ร */ 0x0e23, /* ณ */ 0x0e13],
  KeyO: [/* น */ 0x0e19, /* ฯ */ 0x0e2f],
  KeyP: [/* ย */ 0x0e22, /* ญ */ 0x0e0d],
  BracketLeft: [/* บ */ 0x0e1a, /* ฐ */ 0x0e10],
  BracketRight: [/* ล */ 0x0e25, /* , */ 0x002c],
  Backslash: [/* ฃ */ 0x0e03, /* ฅ */ 0x0e05],
  KeyA: [/* ฟ */ 0x0e1f, /* ฤ */ 0x0e24],
  KeyS: [/* ห */ 0x0e2b, /* ฆ */ 0x0e06],
  KeyD: [/* ก */ 0x0e01, /* ฏ */ 0x0e0f],
  KeyF: [/* ด */ 0x0e14, /* โ */ 0x0e42],
  KeyG: [/* เ */ 0x0e40, /* ฌ */ 0x0e0c],
  KeyH: [/* ้ */ 0x0e49, /* ็ */ 0x0e47],
  KeyJ: [/* ่ */ 0x0e48, /* ๋ */ 0x0e4b],
  KeyK: [/* า */ 0x0e32, /* ษ */ 0x0e29],
  KeyL: [/* ส */ 0x0e2a, /* ศ */ 0x0e28],
  Semicolon: [/* ว */ 0x0e27, /* ซ */ 0x0e0b],
  Quote: [/* ง */ 0x0e07, /* . */ 0x002e],
  IntlBackslash: [/* ฃ */ 0x0e03, /* ฅ */ 0x0e05],
  KeyZ: [/* ผ */ 0x0e1c, /* ( */ 0x0028],
  KeyX: [/* ป */ 0x0e1b, /* ) */ 0x0029],
  KeyC: [/* แ */ 0x0e41, /* ฉ */ 0x0e09],
  KeyV: [/* อ */ 0x0e2d, /* ฮ */ 0x0e2e],
  KeyB: [/* ิ */ 0x0e34, /* ฺ */ 0x0e3a],
  KeyN: [/* ื */ 0x0e37, /* ์ */ 0x0e4c],
  KeyM: [/* ท */ 0x0e17, /* ? */ 0x003f],
  Comma: [/* ม */ 0x0e21, /* ฒ */ 0x0e12],
  Period: [/* ใ */ 0x0e43, /* ฬ */ 0x0e2c],
  Slash: [/* ฝ */ 0x0e1d, /* ฦ */ 0x0e26],
  Space: [/* SPACE */ 0x0020],
};
