// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_TH_TH_PAT: CharacterDict = {
  Backquote: [/* _ */ 0x005f, /* ฿ */ 0x0e3f],
  Digit1: [/* = */ 0x003d, /* + */ 0x002b],
  Digit2: [/* ๒ */ 0x0e52, /* " */ 0x0022],
  Digit3: [/* ๓ */ 0x0e53, /* / */ 0x002f],
  Digit4: [/* ๔ */ 0x0e54, /* , */ 0x002c],
  Digit5: [/* ๕ */ 0x0e55, /* ? */ 0x003f],
  Digit6: [/* ู */ 0x0e39, /* ุ */ 0x0e38],
  Digit7: [/* ๗ */ 0x0e57, /* _ */ 0x005f],
  Digit8: [/* ๘ */ 0x0e58, /* . */ 0x002e],
  Digit9: [/* ๙ */ 0x0e59, /* ( */ 0x0028],
  Digit0: [/* ๐ */ 0x0e50, /* ) */ 0x0029],
  Minus: [/* ๑ */ 0x0e51, /* - */ 0x002d],
  Equal: [/* ๖ */ 0x0e56, /* % */ 0x0025],
  KeyQ: [/* ็ */ 0x0e47, /* ๊ */ 0x0e4a],
  KeyW: [/* ต */ 0x0e15, /* ฤ */ 0x0e24],
  KeyE: [/* ย */ 0x0e22, /* ๆ */ 0x0e46],
  KeyR: [/* อ */ 0x0e2d, /* ญ */ 0x0e0d],
  KeyT: [/* ร */ 0x0e23, /* ษ */ 0x0e29],
  KeyY: [/* ่ */ 0x0e48, /* ึ */ 0x0e36],
  KeyU: [/* ด */ 0x0e14, /* ฝ */ 0x0e1d],
  KeyI: [/* ม */ 0x0e21, /* ซ */ 0x0e0b],
  KeyO: [/* ว */ 0x0e27, /* ถ */ 0x0e16],
  KeyP: [/* แ */ 0x0e41, /* ฒ */ 0x0e12],
  BracketLeft: [/* ใ */ 0x0e43, /* ฯ */ 0x0e2f],
  BracketRight: [/* ฌ */ 0x0e0c, /* ฦ */ 0x0e26],
  Backslash: [/*  */ 0xf8c7, /* ํ */ 0x0e4d],
  KeyA: [/* ้ */ 0x0e49, /* ๋ */ 0x0e4b],
  KeyS: [/* ท */ 0x0e17, /* ธ */ 0x0e18],
  KeyD: [/* ง */ 0x0e07, /* ำ */ 0x0e33],
  KeyF: [/* ก */ 0x0e01, /* ณ */ 0x0e13],
  KeyG: [/* ั */ 0x0e31, /* ์ */ 0x0e4c],
  KeyH: [/* ี */ 0x0e35, /* ื */ 0x0e37],
  KeyJ: [/* า */ 0x0e32, /* ผ */ 0x0e1c],
  KeyK: [/* น */ 0x0e19, /* ช */ 0x0e0a],
  KeyL: [/* เ */ 0x0e40, /* โ */ 0x0e42],
  Semicolon: [/* ไ */ 0x0e44, /* ฆ */ 0x0e06],
  Quote: [/* ข */ 0x0e02, /* ฑ */ 0x0e11],
  IntlBackslash: [/*  */ 0xf8c7, /* ํ */ 0x0e4d],
  KeyZ: [/* บ */ 0x0e1a, /* ฎ */ 0x0e0e],
  KeyX: [/* ป */ 0x0e1b, /* ฏ */ 0x0e0f],
  KeyC: [/* ล */ 0x0e25, /* ฐ */ 0x0e10],
  KeyV: [/* ห */ 0x0e2b, /* ภ */ 0x0e20],
  KeyB: [/* ิ */ 0x0e34, /* ั */ 0x0e31],
  KeyN: [/* ค */ 0x0e04, /* ศ */ 0x0e28],
  KeyM: [/* ส */ 0x0e2a, /* ฮ */ 0x0e2e],
  Comma: [/* ะ */ 0x0e30, /* ฟ */ 0x0e1f],
  Period: [/* จ */ 0x0e08, /* ฉ */ 0x0e09],
  Slash: [/* พ */ 0x0e1e, /* ฬ */ 0x0e2c],
  Space: [/* SPACE */ 0x0020],
};
