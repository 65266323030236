// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_TH_TH_MAN: CharacterDict = {
  Backquote: [/* ` */ 0x0060, /* ~ */ 0x007e],
  Digit1: [/* 1 */ 0x0031, /* ! */ 0x0021, /* ๑ */ 0x0e51],
  Digit2: [/* 2 */ 0x0032, /* @ */ 0x0040, /* ๒ */ 0x0e52],
  Digit3: [/* 3 */ 0x0033, /* # */ 0x0023, /* ๓ */ 0x0e53],
  Digit4: [/* 4 */ 0x0034, /* $ */ 0x0024, /* ๔ */ 0x0e54],
  Digit5: [/* 5 */ 0x0035, /* % */ 0x0025, /* ๕ */ 0x0e55],
  Digit6: [/* 6 */ 0x0036, /* ^ */ 0x005e, /* ๖ */ 0x0e56],
  Digit7: [/* 7 */ 0x0037, /* & */ 0x0026, /* ๗ */ 0x0e57],
  Digit8: [/* 8 */ 0x0038, /* * */ 0x002a, /* ๘ */ 0x0e58],
  Digit9: [/* 9 */ 0x0039, /* ( */ 0x0028, /* ๙ */ 0x0e59],
  Digit0: [/* 0 */ 0x0030, /* ) */ 0x0029, /* ๐ */ 0x0e50],
  Minus: [/* - */ 0x002d, /* _ */ 0x005f, /* ÷ */ 0x00f7],
  Equal: [/* = */ 0x003d, /* + */ 0x002b, /* × */ 0x00d7],
  KeyQ: [/* ใ */ 0x0e43, /* ฒ */ 0x0e12],
  KeyW: [/* ต */ 0x0e15, /* ฏ */ 0x0e0f],
  KeyE: [/* ห */ 0x0e2b, /* ซ */ 0x0e0b],
  KeyR: [/* ล */ 0x0e25, /* ญ */ 0x0e0d],
  KeyT: [/* ส */ 0x0e2a, /* ฟ */ 0x0e1f],
  KeyY: [/* ป */ 0x0e1b, /* ฉ */ 0x0e09],
  KeyU: [/* ั */ 0x0e31, /* ึ */ 0x0e36],
  KeyI: [/* ก */ 0x0e01, /* ธ */ 0x0e18],
  KeyO: [/* ิ */ 0x0e34, /* ฐ */ 0x0e10],
  KeyP: [/* บ */ 0x0e1a, /* ฎ */ 0x0e0e],
  BracketLeft: [/* ็ */ 0x0e47, /* ฆ */ 0x0e06],
  BracketRight: [/* ฬ */ 0x0e2c, /* ฑ */ 0x0e11],
  Backslash: [/* ฯ */ 0x0e2f, /* ฌ */ 0x0e0c],
  KeyA: [/* ง */ 0x0e07, /* ษ */ 0x0e29],
  KeyS: [/* เ */ 0x0e40, /* ถ */ 0x0e16],
  KeyD: [/* ร */ 0x0e23, /* แ */ 0x0e41],
  KeyF: [/* น */ 0x0e19, /* ช */ 0x0e0a],
  KeyG: [/* ม */ 0x0e21, /* พ */ 0x0e1e],
  KeyH: [/* อ */ 0x0e2d, /* ผ */ 0x0e1c],
  KeyJ: [/* า */ 0x0e32, /* ำ */ 0x0e33, /* ๅ */ 0x0e45],
  KeyK: [/* ่ */ 0x0e48, /* ข */ 0x0e02, /* ฃ */ 0x0e03],
  KeyL: [/* ้ */ 0x0e49, /* โ */ 0x0e42],
  Semicolon: [/* ว */ 0x0e27, /* ภ */ 0x0e20],
  Quote: [/* ื */ 0x0e37, /* " */ 0x0022],
  KeyZ: [/* ุ */ 0x0e38, /* ฤ */ 0x0e24, /* ฦ */ 0x0e26],
  KeyX: [/* ไ */ 0x0e44, /* ฝ */ 0x0e1d],
  KeyC: [/* ท */ 0x0e17, /* ๆ */ 0x0e46],
  KeyV: [/* ย */ 0x0e22, /* ณ */ 0x0e13],
  KeyB: [/* จ */ 0x0e08, /* ๊ */ 0x0e4a],
  KeyN: [/* ค */ 0x0e04, /* ๋ */ 0x0e4b, /* ฅ */ 0x0e05],
  KeyM: [/* ี */ 0x0e35, /* ์ */ 0x0e4c],
  Comma: [/* ด */ 0x0e14, /* ศ */ 0x0e28],
  Period: [/* ะ */ 0x0e30, /* ฮ */ 0x0e2e],
  Slash: [/* ู */ 0x0e39, /* ? */ 0x003f],
  Space: [/* SPACE */ 0x0020],
};
